import { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'
import Cookies from 'js-cookie'

export interface TermOfUseModalProps {}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '95%',
    md: '70%',
    lg: '38%'
  },
  maxWidth: '500px',
  margin: '0 auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4
}

export const TermOfUseModal = (props: TermOfUseModalProps) => {
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (!Cookies.get('mangafi_agreement')) {
      setOpen(true)
    }
  }, [])

  const toggleOpen = () => {
    setOpen(state => !state)
  }

  const handleContinue = () => {
    if (!checked) {
      return
    }

    Cookies.set('mangafi_agreement', 'true', {
      expires: 30 // 30 days
    })
    toggleOpen()
  }

  return (
    <Modal open={open} aria-labelledby='tou-modal-title'>
      <Box display='flex' flexDirection='column' alignItems='center' sx={style}>
        <Typography id='tou-modal-title' variant='h2' component='h2'>
          Terms of Use
        </Typography>
        <Box maxHeight='50vh' my='0.5rem' overflow='auto'>
          <p>
            HONO Protocol is an array of decentralised applications that people can use for various financial purposes. Your
            use of HONO Protocol involves various risks, including, but not limited, to losses while digital assets are being
            supplied to HONO Protocol's applications. Additionally, just as you can access email protocols, such as SMTP,
            through multiple email clients, you can access HONO Protocol through several web or mobile interfaces that plug
            into these permissionless and immutable smart contracts. You are responsible for doing your own diligence on
            those interfaces to understand the fees and risks they present.
          </p>
          <br />
          <p>
            AS DESCRIBED IN THE HONO PROTOCOL LICENSES, THE HONO PROTOCOL PLATFORM IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND
            WITHOUT WARRANTIES OF ANY KIND. Upgrades and modifications to the protocol are managed by the project. No
            developer or entity involved in creating the HONO Protocol will be liable for any claims or damages whatsoever
            associated with your use, inability to use, or your interaction with other users of the HONO Protocol platform,
            including any direct, indirect, incidental, special, exemplary, punitive or consequential damages, or loss
            of profits, cryptocurrencies, tokens, or anything else of value.
          </p>
        </Box>

        <Box textAlign='center'>
          <Box my='0.8rem' display='flex' alignItems='center'>
            <Checkbox
              id='tou-checkbox'
              checked={checked}
              onChange={event => setChecked(event.target.checked)}
              sx={{ my: -1, ml: -1 }}
            />
            <Typography
              component='label'
              htmlFor='tou-checkbox'
              variant={'body1'}
              sx={{
                cursor: 'pointer'
              }}
            >
              I understand the risks and would like to proceed
            </Typography>
          </Box>
          <Button variant='contained' color='warning' onClick={handleContinue} disabled={!checked}>
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
