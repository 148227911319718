import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick'
import TextField from '@mui/material/TextField'
import { Exchange } from './Exchange'
import { HONO_V2_ADDRESS, xHONO_ADDRESS } from 'app/utils/constants/contract'
import { MaxAmount } from 'app/components/MaxAmount'
import { useExchangeFee, useHONOInPool } from 'app/hooks/exchange/useExchange'
import { formatNumber } from 'app/utils/helper'
import { useBalance } from 'app/hooks/useBalance'
import { PageTitle } from 'app/components/PageTitle'
import { XHONOConverted } from '../home/xHONOConverted'

export const Convert = () => {
  const { data: totalHONO } = useHONOInPool()
  const { data: balanceHONO } = useBalance(HONO_V2_ADDRESS)
  const [amount, setAmount] = useState('')

  const { data: fee, isLoading } = useExchangeFee(amount)

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
  }

  const handleChangeMax = (maxAmount: string) => {
    if (totalHONO && maxAmount) {
      const value = +maxAmount < +totalHONO ? maxAmount : totalHONO
      setAmount(value)
    }
  }

  return (
    <div>
      <PageTitle title='HONO Protocol DApp: xHONO to HONO' />
      <Typography variant='h2' color='text.primary'>
        Convert xHONO to HONO V2
      </Typography>
      <Typography variant='h6' color='text.secondary' mb={3}>
        xHONO to HONO V2 is a 1:1 conversion with a 2.5% fee. This conversion is subject to the availability of HONO V2 in the
        pool. To increase the rate of building up this pool kindly vote for “xHONO Pool” in the vote tab.
      </Typography>

      <Box>
        {/* @ts-ignore */}
        <JumboCardQuick
          title={
            <Typography variant={'h2'} textAlign='center'>
              {totalHONO ? formatNumber(+totalHONO) : '...'} HONO V2
            </Typography>
          }
          subheader={
            <Typography variant={'h6'} mb={0} textAlign='center'>
              available in pool
            </Typography>
          }
          headerSx={{ pb: 0 }}
          sx={{ pb: 0 }}
        >
          <Typography variant={'h2'} textAlign='center'>
            {balanceHONO ? formatNumber(+balanceHONO) : '...'} HONO V2
          </Typography>
          <Typography variant={'h6'} mb={0} textAlign='center'>
            in your balance
          </Typography>
          <Box mt='2rem' textAlign='center'>
            <Typography variant='h5' color='text.primary'>
              Convert xHONO to HONO V2
            </Typography>

            <Box
              mx='auto'
              mb='0.5rem'
              sx={{
                width: {
                  xs: '100%',
                  sm: '360px'
                }
              }}
            >
              <Box textAlign='right'>Fee: {isLoading ? `loading...` : fee ? formatNumber(fee) : 0} ETH</Box>
              <TextField
                label='xHONO'
                variant='outlined'
                type='number'
                sx={{
                  width: {
                    xs: '100%',
                    sm: '360px'
                  }
                }}
                onChange={handleChangeAmount}
                value={amount}
                InputLabelProps={{ shrink: true }}
              />
              <MaxAmount tokenAddress={xHONO_ADDRESS} onChangeAmount={handleChangeMax} />
            </Box>
            <Exchange amount={amount} fee={fee + '' || '0'} />
          </Box>
        </JumboCardQuick>

        <Box mt='1rem'>
          <XHONOConverted transparentHeader />
        </Box>
      </Box>
    </div>
  )
}
