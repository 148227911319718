import Typography from '@mui/material/Typography'

import { ReferralList } from './ReferralList'
import { ReferrerLink } from './ReferrerLink'
import { PageTitle } from 'app/components/PageTitle'

export const Referral = () => {
  return (
    <div>
      <PageTitle title='HONO Protocol DApp: Referral' />
      <Typography variant='h2' color='text.primary'>
        Referral
      </Typography>
      <Typography variant='body1' color='text.secondary' mb={3}>
        As a referrer, you will get 0.05% of the referred liquidity provider's FIRST HARVEST. Do note that this value is
        capped at 0.01 ETH per referred liquidity provider and only the FIRST HARVEST of the liquidity provider will
        contribute to your earnings.
      </Typography>
      <ReferrerLink />

      <ReferralList />
    </div>
  )
}
