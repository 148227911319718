import { useState } from 'react'
import { Address } from 'viem'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import toast from 'react-hot-toast'

import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { useLockedAmount, useUnLockPool } from 'app/hooks/exchange/useExchange'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'
import { VoteType } from './VoteItem'
import { formatNumber } from 'app/utils/helper'

export interface VoteActionProps {
  tokenAddress: Address
  lockPool: VoteType
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  margin: '0 auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 6,
  textAlign: 'center' as 'center'
}

export const UnVoteAction = ({ lockPool, tokenAddress }: VoteActionProps) => {
  const { theme } = useJumboTheme() as any

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [amount, setAmount] = useState('')
  const { data: lockedAmount } = useLockedAmount()
  const poolLockedAmount = lockPool === VoteType.DAO ? lockedAmount?.lockedDao : lockedAmount?.lockedPool

  const toggleOpen = () => {
    setOpen(state => !state)
  }

  const { mutateAsync: unvote } = useUnLockPool(lockPool)

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
  }

  const handleVote = async () => {
    try {
      setLoading(true)
      await unvote({
        amount
      })
      toggleOpen()
    } catch (err) {
      toast.error(JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  if (!poolLockedAmount || +poolLockedAmount === 0) return null

  return (
    <ConnectWalletGuard>
      <NetworkGuard>
        {/* <AllowanceGuard tokenAddress={tokenAddress} spenderAddress={LOCK_POOL_ADDRESS} tokenName='xHONO'> */}
        <LoadingButton loading={loading} variant={'outlined'} size={'small'} onClick={toggleOpen}>
          Unvote
        </LoadingButton>

        <Modal open={open} onClose={toggleOpen} aria-labelledby='vote-modal-title'>
          <Box display='flex' flexDirection='column' alignItems='center' sx={style}>
            <Typography id='vote-modal-title' variant='h4' component='h2'>
              Unvote Amount
            </Typography>
            <Box
              sx={{
                width: '320px',
                mb: '1rem'
              }}
            >
              <TextField
                label='xHONO'
                variant='outlined'
                type='number'
                sx={{
                  width: '100%'
                }}
                onChange={handleChangeAmount}
                value={amount}
                InputLabelProps={{ shrink: true }}
              />
              <Box display='flex' justifyContent='space-between'>
                <Box>Balance: {poolLockedAmount ? formatNumber(+poolLockedAmount) : 0}</Box>
                {poolLockedAmount && +poolLockedAmount > 0 && (
                  <Box
                    sx={{
                      cursor: 'pointer'
                    }}
                    color={theme.palette.primary.main}
                    onClick={() => setAmount(poolLockedAmount)}
                  >
                    Max
                  </Box>
                )}
              </Box>
            </Box>

            <LoadingButton
              loading={loading}
              variant={'outlined'}
              size={'small'}
              color='secondary'
              disabled={!amount}
              onClick={handleVote}
            >
              Unvote
            </LoadingButton>
          </Box>
        </Modal>
        {/* </AllowanceGuard> */}
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
