import React from 'react'
import toast from 'react-hot-toast'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import { HONO_V2_ADDRESS } from 'app/utils/constants/contract'

export const HONOInformation = () => {
  const handleCopy = () => {
    navigator.clipboard.writeText(HONO_V2_ADDRESS)
    toast.success('Copied to clipboard')
  }

  return (
    <Card sx={{ textAlign: 'center' }}>
      <CardContent
        sx={{
          backgroundImage: 'linear-gradient(#ffce22, #df752c)',
          backgroundRepeat: 'repeat-x',
          pt: '18px',
          pb: '36px'
        }}
      >
        <img src='/hono-icon.svg' alt='HONO' width={52} height={52} />
        <Typography variant={'h2'} fontSize={16} color='#fff' mb={-0.5}>
          TOKEN
        </Typography>
        <Typography variant={'h1'} fontSize={32} fontWeight={500} mb={1} color='#fff'>
          HONO V2
        </Typography>
        <Typography
          variant={'h3'}
          fontSize={14}
          sx={{
            wordBreak: 'break-word'
          }}
          color='#fff'
        >
          {HONO_V2_ADDRESS}
        </Typography>
      </CardContent>
      <CardContent sx={{ position: 'relative' }}>
        <Fab
          aria-label='paste'
          sx={{
            position: 'absolute',
            left: '50%',
            top: '0',
            height: 60,
            width: 60,
            transform: 'translate(-50%, -50%)',
            color: '#fff',
            background: '#37474f'
          }}
          onClick={handleCopy}
        >
          <ContentPasteIcon fontSize='medium' />
        </Fab>
        <Typography my={2.25}>
          HONO V2 is backed & minted through wstETH deposits and we are expect it
          to growth between 8% - 25% per annum.
        </Typography>

        <Button
          variant={'contained'}
          color={'inherit'}
          href='https://docs.honoprotocol.com/hono-mechanics/what-is-hono/'
          target='_blank'
          disableElevation
        >
          Learn More
        </Button>
      </CardContent>
    </Card>
  )
}
