import { useMemo } from 'react'
import { Button, Card, CardContent, Typography } from '@mui/material'
import format from 'date-fns/format'

import { useETHPrice, useHONOPrice, useLinkPrice } from 'app/hooks/graph/useGraph'
import { useLatestRevenue, useTotalRevenue, useTotalRevenueV2 } from 'app/hooks/useBackPrice'
import { formatCurrency, formatNumber } from 'app/utils/helper'
import { useBalanceOf, useNativeBalanceOf } from 'app/hooks/useBalance'
import { DISTRIBUTOR_ADDRESS, HONO_V2_ADDRESS } from 'app/utils/constants/contract'

export const Revenue = () => {
  const { data: totalRevenue1 } = useTotalRevenue()
  const { data: totalRevenue2 } = useTotalRevenueV2()
  const { data: latestRevenue } = useLatestRevenue()
  const { data: ethPrice } = useETHPrice()
  const { data: linkPrice } = useLinkPrice()
  const { data: honoPrice } = useHONOPrice()
  const { data: pendingEth } = useNativeBalanceOf(DISTRIBUTOR_ADDRESS)
  const { data: pendingLink } = useBalanceOf({
    tokenAddress: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    contractAddress: DISTRIBUTOR_ADDRESS
  })
  const { data: pendingHONO } = useBalanceOf({
    tokenAddress: HONO_V2_ADDRESS,
    contractAddress: DISTRIBUTOR_ADDRESS
  })
  const { data: pendingUSDC } = useBalanceOf({
    tokenAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    contractAddress: DISTRIBUTOR_ADDRESS
  })

  const totalRevenue = useMemo(() => {
    let revenue = 0
    if (totalRevenue1) {
      revenue += +totalRevenue1
    }

    if (totalRevenue2) {
      revenue += +totalRevenue2
    }

    return revenue
  }, [totalRevenue1, totalRevenue2])

  const pendingEthValue = pendingEth && ethPrice ? +pendingEth * ethPrice : 0
  const pendingLinkValue = pendingLink && linkPrice ? +pendingLink * linkPrice : 0
  const pendingHONOValue = pendingHONO && honoPrice ? +pendingHONO * honoPrice : 0
  const pendingRevenue = pendingEthValue + pendingLinkValue + pendingHONOValue + (pendingUSDC ? +pendingUSDC : 0)
  const latestRevenueValue = latestRevenue && ethPrice && formatCurrency(+formatNumber(+latestRevenue.value) * ethPrice)

  return (
    <Card>
      <CardContent
        sx={{ pt: '12px', pb: '8px', textAlign: 'center', color: 'common.white', bgcolor: 'rgb(55, 71, 79)' }}
      >
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          Revenue
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
          {totalRevenue && `~${formatNumber(+totalRevenue)} ETH`}
        </Typography>
        <Typography mt='-0.5rem' mb='0.5rem' fontSize='14px'>
          {totalRevenue && ethPrice && `(${formatCurrency(+formatNumber(+totalRevenue) * ethPrice)})`}
        </Typography>
        <Typography variant={'h3'} fontSize={'16px'} mb={3}>
          Total Income Distributed since 8th August 2023
        </Typography>

        <Typography variant={'h3'} fontSize={'14.5px'} mb='2px'>
          {!!pendingRevenue && `Pending income: ~${formatCurrency(pendingRevenue)}`}
        </Typography>
        <Typography variant={'h3'} fontSize={'14.5px'} mb={3}>
          {latestRevenue &&
            latestRevenueValue &&
            `Latest distributed income (${format(
              new Date(latestRevenue?.date),
              'dd/MM/yyyy HH:mm'
            )}): ~${latestRevenueValue}`}
        </Typography>

        <Typography variant={'h2'} fontSize={'14px'} mb={1}>
          Revenue is the amount of funds earned through our Real Yield Revenue Resources
        </Typography>

        <Button
          variant={'contained'}
          color={'inherit'}
          href='https://docs.honoprotocol.com/overview/real-yield-revenue-resources'
          target='_blank'
          disableElevation
        >
          Learn More
        </Button>
      </CardContent>
    </Card>
  )
}
