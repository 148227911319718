import { useEffect } from 'react'
import { Address } from 'viem'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { TotalBacking } from './TotalBacking/TotalBacking'
import { RateOfIncrease } from './RateOfIncrease/RateOfIncrease'
import { useBackPrice, useBackPrice2, useETHBalanceInHONO, useETHBalanceInHONOV2 } from 'app/hooks/useBackPrice'
import { TotalBackingChart } from './TotalBacking/Chart'
import { setStorage } from 'app/utils/helper'
import { StakingAPR } from './StakingAPR/StakingAPR'
import { XHONOSupply } from './xHONOSupply'
import { TVL } from './TVL'
import { Revenue } from './Revenue'
import { XHONOConverted } from './xHONOConverted'
import { DAO } from './DAO'
import { PageTitle } from 'app/components/PageTitle'
import { HONOInformation } from './HONOInformation'
import { ReferralInformation } from './ReferralInformation'
import { XHONOInformation } from './XHONOInformation'
import { CommunityGoal } from './CommunityGoal'
import { useEncryptAddress } from 'app/hooks/useBalance'

export const Home = () => {
  const address = useEncryptAddress()
  const location = useLocation()
  const defaultReferrer = new URLSearchParams(location.search).get('referrer') as Address

  useEffect(() => {
    if (defaultReferrer && address) {
      if (defaultReferrer.toLowerCase() === address.toLowerCase()) return

      setStorage('@mangafi_referrer', defaultReferrer)
    }
  }, [defaultReferrer, address])

  const { data: backPrices } = useBackPrice()
  const { data: backPrices2 } = useBackPrice2()

  const { data: ethBalance } = useETHBalanceInHONO()
  const { data: ethBalanceV2 } = useETHBalanceInHONOV2()

  return (
    <Box>
      <PageTitle title='HONO Protocol DApp: Dashboard' />
      <Typography variant='h2' color='text.primary'>
        Dashboard
      </Typography>

      <Box mt='1.25rem'>
        <CommunityGoal />
      </Box>

      <Box my='2rem'>
        <TotalBackingChart backPrices={backPrices || []} backPrices2={backPrices2 || []} />
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: 'repeat(2, 1fr)'
          },
          gap: '2rem',
          maxWidth: '1080px',
          margin: '0 auto'
        }}
      >
        <TotalBacking ethBalance={ethBalance ? +ethBalance : 0} ethBalanceV2={ethBalanceV2 ? +ethBalanceV2 : 0} />
        <StakingAPR />
        <RateOfIncrease />
        <XHONOSupply />
        <TVL />
        <Revenue />
        <XHONOConverted />
        <DAO />
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(3, 1fr)'
          },
          gap: '2rem',
          maxWidth: '1080px',
          margin: '2rem auto'
        }}
      >
        <HONOInformation />
        <ReferralInformation />
        <XHONOInformation />
      </Box>
    </Box>
  )
}
