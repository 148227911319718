import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Box } from '@mui/material'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

export interface AnnouncementModalProps {}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '100%',
    lg: '540px'
  },
  height: {
    xs: '100vh',
    lg: 'calc(100vh - 72px)'
  },
  maxWidth: '540px',
  margin: {
    xs: '0',
    lg: '0 auto'
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  scrollBarGutter: 'stable both-edges',
  outline: 'none',

  msOverflowStyle: 'none',
  scrollbarWidth: 'none',

  '&::-webkit-scrollbar': {
    display: 'none'
  }
}

const useAnnouncement = () => {
  return useQuery(['announcement'], async () => {
    const data = await fetch('https://assets.honoprotocol.com/app/promotion.json').then(res => res.json())

    return data.content
  })
}

export const AnnouncementModal = () => {
  const [open, setOpen] = useState(false)
  const { data: content } = useAnnouncement()

  useEffect(() => {
    if (content) {
      setOpen(true)
    }
  }, [content])

  const toggleOpen = () => {
    setOpen(state => !state)
  }

  return content ? (
    <Modal open={open} onClose={toggleOpen} aria-labelledby='tou-modal-title'>
      <Box sx={style} overflow='auto'>
        <Box
          position='sticky'
          display='inline-block'
          width='40px'
          height='40px'
          top='20px'
          left='calc(100% - 40px - 20px)' // 40 is width, 20 is actaul right we need
          py='7px'
          px='8px'
          borderRadius='100%'
          bgcolor='#fff'
          zIndex={10000}
          sx={{
            cursor: 'pointer'
          }}
          onClick={toggleOpen}
        >
          <CloseIcon />
        </Box>
        <Box marginTop='-40px'>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </Box>
      </Box>
    </Modal>
  ) : null
}
