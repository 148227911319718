import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { VoteList } from './VoteList'
import { useBalance } from 'app/hooks/useBalance'
import { xHONO_ADDRESS } from 'app/utils/constants/contract'
import { useLockedAmount } from 'app/hooks/exchange/useExchange'
import { formatNumber } from 'app/utils/helper'
import { PageTitle } from 'app/components/PageTitle'

export const Vote = () => {
  const { data: balance } = useBalance(xHONO_ADDRESS)
  const { data: lockedAmount } = useLockedAmount()

  return (
    <div>
      <PageTitle title='HONO Protocol DApp: Vote' />
      <Typography variant='h2' color='text.primary'>
        Vote
      </Typography>
      <Typography variant='body1' color='text.secondary' mb={3}>
        Vote your pool.
      </Typography>

      <Box mb='1rem'>
        <Typography variant='h4' color='text.primary'>
          {lockedAmount
            ? `Your Staked xHONO: ${formatNumber(+lockedAmount.lockedDao + +lockedAmount.lockedPool)}`
            : `Your Staked xHONO: ...`}
        </Typography>
        <Typography variant='h4' color='text.primary'>
          {balance ? `xHONO Balance: ${formatNumber(+balance)}` : 'xHONO Balance: ...'}
        </Typography>
      </Box>
      <VoteList />
    </div>
  )
}
